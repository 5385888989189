
.card {
  padding: 24px 32px;
  background: $colorWhite;
  border-radius: 8px;
  box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.1), 0 20px 40px -20px rgba(0, 0, 0, .1);

  // .pretitle {
  //   display: inline-block;
  //   margin-bottom: 16px;
  //   padding: 4px 8px 2px;
  //   font-size: 1.2rem;
  //   background-color: $colorWhite;
  //   border-radius: 4px;
  // }

  h2 {
    margin-bottom: 16px;
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 1.4;
  }

  p {
    line-height: 1.6;
  }
}
