// =============================================================================
//
// @file sass-settings.scss
// Main output file combining all subtheme components
//
// =============================================================================


/*------------------------------------------------------------------------------
  [Table of contents]

  1. COLOR SCHEME
    1.1 COLOR SCHEME By use
  2. TYPOGRAPHY Font Imports
  3. BASE THEME FRAMEWORK
------------------------------------------------------------------------------*/


// ========================================
// 1. COLOR SCHEME
// ========================================
$colorWhite: #ffffff;
$colorBlack: #151515;
$colorOrange: #FD7D48;
$colorOrangeDark: #e37241;
$colorGreyLight: #F1F5F9;
$colorGreyDark: #566072;
/*-----------------------------------------
  1.1 COLOR SCHEME By use
-----------------------------------------*/
$text-color         : $colorBlack;
// $link-color         : $colorGvzDark;
// $link-color-hover   : $colorGvzRed;



// ========================================
// 2. TYPOGRAPHY Font Imports
// ========================================
@import url('https://fonts.bunny.net/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.bunny.net/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
$fontHeading: 'Manrope', sans-serif;
$fontText: 'Inter', sans-serif;


// ========================================
// 3. BASE THEME FRAMEWORK
// ========================================
/*-----------------------------------------
  To use bootstrap framework uncomment this two lines and check files settings
  @todo: Using @import statements within control directives or mixins
  https://github.com/sass/sass/issues/1194
-----------------------------------------*/
// @import 'bootstrap-reboot';
@import 'bootstrap-grid';

