
html {
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

body {
  @extend %anti-aliasing;
  @extend %kerning;
  color: #151515;
  font-family: $fontText;
  font-size: 1.8rem;
  line-height: 1.4;
}

a {
  text-decoration: none;
  // color: $link-color;
  transition: all 200ms ease;

  &:hover{
    color: $colorOrangeDark;
  }
}

img {
  display: block;
  height: auto;
  margin: 0;
  max-width: 100%;
}

.img-wr {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

h1, h2, h3, h4 {
  font-family: $fontHeading;
  font-weight: 700;
}

h1 {
  margin-bottom: 24px;
  font-size: 6.8rem;
  font-weight: 800;
  line-height: 1.2;
}

h2 {
  margin-bottom: 24px;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.2;
}

.pretitle {
  font-family: $fontHeading;
  margin-bottom: 8px;
  color: $colorGreyDark;
  font-size: 1.4rem;
  font-weight: 800;
  text-transform: uppercase;
}

.subtitle {
  margin-bottom: 40px;
  color: $colorGreyDark;
  font-size: 2.1rem;
}
