// =============================================================================
//
// @file ignite-bootstrap-variables.scss
//
// Use this file to change the default bootstrap variables.
// Default variables: /sgt_ignite/ignite_core/.npm/node_modules/bootstrap/scss/_variables.scss
//
// A recent example was on last years symposium.org redesign project, where
// we had to change grid breakpoints and container widths.
//
// =============================================================================

/*------------------------------------------------------------------------------
 [Table of contents]

 0. Example
------------------------------------------------------------------------------*/

// ========================================
//   0. Example
// ========================================
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-gutter-width: 24px;
