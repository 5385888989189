/*!
 * Bootstrap Grid v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

@import "../../.npm/node_modules/bootstrap/scss/functions";
@import "../../.npm/node_modules/bootstrap/scss/variables";
@import "ignite-bootstrap-variables";
@import "../../.npm/node_modules/bootstrap/scss/mixins";
@import "../.npm/node_modules/bootstrap/scss/grid";
@import "../.npm/node_modules/bootstrap/scss/utilities/display";
@import "../.npm/node_modules/bootstrap/scss/utilities/flex";
