.logotype {
  display: inline-block;
  width: 144px;
  height: 40px;
  background-image: url('../images/castee.svg');
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

.header {
  width: 100%;
  position: absolute;
  top: 16px;
  z-index: 5;
  
  .container {
    padding-top: 12px;
    padding-bottom: 12px;
    background: $colorWhite;
    border-radius: 16px;
    box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.1), 0 20px 40px -20px rgba(0, 0, 0, .1);
  }

  .wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  ul {
    display: inline-block;
  }

  li {
    display: inline-block;
  }

  .nav {
    margin-right: auto;
    margin-left: 40px;

    a {
      padding: 4px 16px;
      color: $colorBlack;
      font-size: 1.6rem;
      font-weight: 600;

      &:hover {
        color: $colorOrangeDark;
      }
    }
  }
}


.mt-m2 {
  margin-top: -80px;
}


.bg--gradient {
  background: linear-gradient(180deg, #f6f9fc, #f6f9fc 41.32%, #fff);
}
.bg--gradient--rev {
  background: linear-gradient(0deg, #f6f9fc, #f6f9fc 41.32%, #fff);
}
.bg--grey {
  background: #f6f9fc;
  // background: $colorGreyLight;
}
.bg--orange {
  background: #ffeadd !important;
}
.bg--green {
  background: #ddffe5 !important;
}
.bg--blue {
  background: #ddefff !important;
}
.bg--purple {
  background: #e7ddff !important;
}


.text--right {
  text-align: right;
}
.text--center {
  text-align: center;
}