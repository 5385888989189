// =============================================================================
//
// @file extensions.scss
// Subtheme extensions
//
// =============================================================================

/*-----------------------------------------
 2. Kerning
-----------------------------------------*/
%kerning {
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "kern";
  font-feature-settings: "kern";
  -webkit-font-kerning: normal;
  font-kerning: normal;
}

/*-----------------------------------------
 3. Anti Aliasing
-----------------------------------------*/
%anti-aliasing {
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.04);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
