
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 8px;
  
  
  
  &.btn--primary {
    color: $colorWhite;
    background-color: $colorOrange;
    border: 1px solid $colorOrange;

    &:hover {
      background-color: $colorOrangeDark;
      border-color: $colorOrange;
    }
  }

  &.btn--secondary {
    color: $colorBlack;
    // border: 1px solid $colorGreyDark;
  }

  &.btn--sm {
    padding: 8px 16px;
    font-size: 1.4rem;
  }
}
