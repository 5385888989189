
.section {
  padding: 64px 0;

  &.pt-0 {
    padding-top: 0;
  }
}

.section--group {
  padding: 0;
}

.section--hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 180px 0;
  position: relative;
  text-align: center;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.06;
    background-image: url('../images/bg--hero.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
}

.section--features {
  padding-bottom: 0;

  .container {
    position: relative;
    top: -40px;
  }
  .row {
    margin-bottom: 80px;
  }

  .card {
    .pretitle {
      display: inline-block;
      margin-bottom: 16px;
      padding: 4px 8px 2px;
      font-size: 1.2rem;
      background-color: $colorWhite;
      border-radius: 4px;
    }
  }

  ul {
    margin-top: 32px;
    column-count: 2;
  }

  li {
    -webkit-column-break-inside: avoid;
    margin-bottom: 8px;
    padding-left: 16px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      position: absolute;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background: $colorBlack;
    }

    p {
      display: inline;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  img {
    max-width: 240px;
    // opacity: .9;
  }
}

.section--pricing {
  padding-top: 0;

  .row {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  
  .price {
    margin-bottom: 8px;
    font-size: 1.6rem;

    span {
      font-size: 3.4rem;
      font-weight: 700;
    }
  }

  .downloads {
    margin-bottom: 24px;
    font-weight: 700;
  }

  ul {
    margin-bottom: 24px;
    font-size: 1.6rem;
  }
}



.section--faq {
  padding-top: 0;
}
.accordion-wr {
  margin-bottom: 24px;
  padding: 24px 32px;
  background: $colorGreyLight;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  .title {
    font-family: $fontHeading;
    font-weight: 900;
    font-size: 2.2rem;
  }

  .content {
    display: none;
    margin-top: 8px;
  }
}


.section--cta {
  .card {
    color: $colorWhite;
    background: #ef772c !important;
  }
  h5 {
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 14px;
    font-size: 2.4rem;
  }
}



.section--footer {
  .bottom {
    margin-top: 56px;
    font-size: 1.4rem;

    ul {
      margin: 0 -8px;
    }

    li {
      display: inline-block;
    }

    a {
      padding: 0 8px;
      color: $colorBlack;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}